<template>
  <v-col cols="4" class="pa-0 pl-5">
    <v-card-title class="text-h5 primary--text justify-center font-weight-bold">Donations</v-card-title>
    <v-card tile elevation="5" :loading="donationLoading" class="height-calc">
      <template slot="progress">
        <v-progress-linear
          color="secondary"
          height="4"
          indeterminate
        ></v-progress-linear>
      </template>
      <div class="primary py-3 px-4 d-flex stepfooter-page">
        <v-text-field hide-details="auto" class="primary--text mr-5" placeholder="Search" dense solo
                      prepend-inner-icon="mdi-magnify" v-model="search" @input="searchDonation"/>
        <v-btn type="button" color="info" class="ma-0" :disabled="donationLoading || donationClearLoading" @click="showAmtClr">Clear</v-btn>
      </div>
      <vue-perfect-scrollbar @ps-scroll-y="donateScroll" class="donate-container" :settings="{'maxScrollbarLength': 60}">
        <template v-if="donationLoading">
          <v-skeleton-loader v-for="(item, i) in [1,2,3,4]" :key="i" class="mb-0"
                             v-bind="{class: 'producer-story-loader'}"
                             type="list-item-two-line, actions, divider"
          ></v-skeleton-loader>
        </template>
        <v-row class="ma-0 pa-8 pt-16 text-h6 secondary--text" aling="center" justify="center" v-else-if="!donationLoading && search && donateLists.length === 0">
          No Data Found!.
        </v-row>
        <v-card v-else tile v-for="(item, i) in donateLists" :key="i">
          <v-row class="ma-0 pa-5 pb-2" :id="i">
            <v-col class="pa-0">
              <div class="pa-0 font-weight-bold text-capitalize" v-if="!item.edit && item.do_not_read === 0">
                {{ `${item.donor_name}` }}
              </div>
              <v-row>
                <v-col cols="12" sm="6" v-if="item.edit && item.do_not_read === 0">
                  <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">First Name</v-subheader>
                  <v-text-field dense outlined class="pa-0 pb-2" hide-details="auto" v-model="item.donor_first_name"
                                placeholder="First Name"/>
                </v-col>
                <v-col cols="12" sm="6" v-if="item.edit && item.do_not_read === 0">
                  <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Last Name</v-subheader>
                  <v-text-field dense outlined class="pa-0 pb-2" hide-details="auto" v-model="item.donor_last_name"
                                placeholder="Last Name"/>
                </v-col>
              </v-row>
              <div v-if="!item.edit" class="pa-0 text-subtitle-1 text-uppercase">
                <span v-if="item.donor_suburb" class="text-uppercase mr-2">{{ item.donor_suburb }}</span>
                <span v-if="item.amount">$ {{ parseInt(item.amount) }}</span>
              </div>
              <v-row>
                <v-col cols="12" sm="6" v-if="item.edit">
                  <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Suburb</v-subheader>
                  <v-text-field dense outlined class="pa-0" hide-details="auto" v-model="item.donor_suburb"
                                placeholder="Donor Suburb"/>
                </v-col>
                <v-col cols="12" sm="6" v-if="item.edit">
                  <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Donation Is On Behalf Of</v-subheader>
                  <v-text-field dense outlined class="pa-0" hide-details="auto" v-model="item.donation_behalf"
                                placeholder="Donor Suburb"/>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="!item.edit && item.created_at" cols="3" class="d-flex justify-end align-center pa-0 font-weight-bold">
              <v-icon size="25" v-if="item.source === 'Call Centre'" color="info" class="mr-2">mdi-phone</v-icon>
              <v-icon size="25" v-if="item.source === 'Web Donation Form'" color="info" class="mr-2">mdi-application-outline</v-icon>
              <v-icon size="25" v-if="item.recurring" color="info" class="mr-2">mdi-autorenew</v-icon>
              <v-icon size="25" v-if="item.new_donor === 1" color="info" class="mr-2">mdi-new-box</v-icon>
              <span>{{ $helpers.getMomentDatas('hh:mm A', item.created_at) }}</span>
            </v-col>
          </v-row>
          <v-row align="center" justify="space-between" justify-lg="space-between" class="ma-0 background px-1">
            <div>
              <v-icon color="info" v-if="item.on_air_thanked" class="ml-4">mdi-check</v-icon>
              <v-btn type="button" text class="text-capitalize text-subtitle-2 ma-0" color="info"
                     @click="!item.on_airLoading && toggleSwitch(item, 'on_air', i)">
                <v-switch dense label="" :loading="item.on_airLoading" color="info" hide-details="auto" class="ma-0 pa-0 mr-1"
                          v-model="item.on_air" :false-value="0" :true-value="1" readonly></v-switch>
                On Air: <small>( {{ `${item.donor_first_name}, ${item.donor_suburb} $${parseInt(item.amount)}` }} )</small>
              </v-btn>
            </div>
            <div>
              <v-btn type="button" text class="text-capitalize text-subtitle-2 ma-0 pr-0" color="info"
                     @click="!item.visible_priceLoading && toggleSwitch(item, 'visible_price', i)">
                <v-switch dense label="" color="info" :loading="item.visible_priceLoading" hide-details="auto" class="ma-0 pa-0 ml-1"
                          v-model="item.visible_price" :false-value="0" :true-value="1" readonly></v-switch>
                Show $
              </v-btn>
              <v-btn text :disabled="item.updateLoading" type="button" class="text-capitalize text-subtitle-2 ma-0 pl-3 ml-1" color="info" @click="editDonationItem(item, i)">
                <v-progress-circular v-if="item.updateLoading" indeterminate color="primary" size="15" width="2"></v-progress-circular>
                <v-icon dark class="cursor-pointer" color="info" v-else>
                  {{ !item.edit ? "mdi-square-edit-outline" : "mdi-content-save-outline" }}
                </v-icon>
              </v-btn>
            </div>
          </v-row>
        </v-card>
      </vue-perfect-scrollbar>
    </v-card>
  </v-col>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      search: '',
      page: 1,
      donateLists: [],
      donationLoading: false,
      donationClearLoading: false,
      timeoutID: null
    }
  },
  created() {
    this.donationLoading = true;
    this.getDonationLists(`if=0&page=1`).then(async (resp) => {
      await resp.map(item => item.edit = item.on_airLoading = item.visible_priceLoading = false);
      this.donateLists = resp;
      this.donationLoading = false;
    }).catch(err => this.donationLoading = false);
  },
  mounted() {
    this.wsDonationUpdate();
  },
  methods: {
    ...mapActions(['getDonationLists', 'updateDonate', 'donationClear']),

    donateScroll(evt) {
      if (Math.ceil(evt.target.scrollTop + evt.target.clientHeight) >= evt.target.scrollHeight) {
        this.page++;
        const endPoint = this.search ? `if=0&page=${this.page}&q=${this.search}` : `if=0&page=${this.page}`;
        this.getDonationLists(endPoint).then(response => {
          if (response.length) {
            response.forEach(item => {
              item.edit = item.on_airLoading = item.visible_priceLoading = false
              this.donateLists.push(item);
            });
          }
        }).catch(err => {
        });
      }
    },
    searchDonation(search) {
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
        this.timeoutID = false;
      }
      const self = this;
      this.timeoutID = setTimeout(async () => {
        self.$store.dispatch('cancelPendingRequests');
        self.donationLoading = true;
        self.page = 1;
        const endPoint = search ? `if=0&page=1&q=${search}` : 'if=0&page=1';
        this.getDonationLists(endPoint).then(async (response) => {
          if (response.length) {
            await response.map(item => item.edit = item.on_airLoading = item.visible_priceLoading = false);
          }
          self.donateLists = response.length ? response : [];
          self.donationLoading = false;
        }).catch(err => self.donationLoading = false);
        self.timeoutID = false;
      }, 500);
    },
    editDonationItem(item, index) {
      if (!item.updateLoading) {
        if (item.edit) {
          item.updateLoading = true;
          this.donateLists.splice(index, 1, item);
          const reqData = {
            id: item.id,
            donor_infos: {
              first_name: item.donor_first_name,
              last_name: item.donor_last_name,
              suburb: item.donor_suburb,
            },
            donation_behalf: item.donation_behalf,
          }
          this.updateDonate(reqData).then(resp => {
            item.edit = item.updateLoading = false;
          }).catch(() => item.edit = item.updateLoading = false);
        } else {
          item.edit = !item.edit;
        }
        this.donateLists.splice(index, 1, item);
      }
    },
    async toggleSwitch(item, name, index) {
      item[`${name}Loading`] = true;
      this.donateLists.splice(index, 1, item);
      const reqData = { id: item.id };
      reqData[name] = item[name] === 1 ? 0 : 1;
      await this.updateDonate(reqData).then((res) => {
        item[name] = item[name] === 1 ? 0 : 1;
        item[`${name}Loading`] = false;
      }).catch();
      if (!item.on_air_thanked && name === 'on_air' && item[name]) {
        item.on_air_thanked = 1;
      }
      this.donateLists.splice(index, 1, item);
    },
    showAmtClr() {
      if (this.donateLists.length > 0) {
        this.donationClearLoading = true;
        this.donationClear().then(resp => this.donationClearLoading = false)
          .catch(err => this.donationClearLoading = false);
      }
    },
    wsDonationUpdate() {
      Echo.channel('donation').listen('.created', (donationItem) => {
        if (donationItem.inspiration_fund === 0) {
          donationItem.edit = donationItem.on_airLoading = donationItem.visible_priceLoading = false;
          this.donateLists.splice(0, 0, donationItem);
        }
      });
      Echo.channel('donation').listen('.updated', (donationItem) => {
        const updatedIndex = this.donateLists.findIndex(item => item.id === donationItem.id);
        if (updatedIndex !== -1) {
          if (donationItem.inspiration_fund === 0) {
            donationItem.edit = donationItem.updateLoading = donationItem.on_airLoading = donationItem.visible_priceLoading = false;
            this.donateLists.splice(updatedIndex, 1, donationItem);
          } else {
            this.donateLists.splice(updatedIndex, 1);
          }
        }
      });
      Echo.channel('donation').listen('.clear', () => {
        this.donateLists.map(item => item.on_air = 0);
      });
    }
  },
  beforeDestroy() {
    Echo.leaveChannel('donation');
  }
}
</script>


<style lang="scss">
.donate-container {
  position: relative;
  margin: auto;
  height: 737px;
}

.height-calc {
  height: calc(100% - 110px);
}
</style>